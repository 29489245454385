import React, { Component } from 'react';
import { BookOpen, LogOut } from 'react-feather';
import Hammer from 'react-hammerjs';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import SidebarHeader from 'layouts/components/menu/vertical-menu/SidebarHeader';
import SideMenuContent from 'layouts/components/menu/vertical-menu/sidemenu/SideMenuContent';
import { logoutApp } from 'redux/actions/auth';
import { ContextLayout } from 'utility/context/Layout';
import { Capacitor } from '@capacitor/core';
class Sidebar extends Component {
  static getDerivedStateFromProps(props, state) {
    if (props.activePath !== state.activeItem) {
      return {
        activeItem: props.activePath,
      };
    }
    // Return null if the state hasn't changed
    return null;
  }
  state = {
    width: window.innerWidth,
    activeIndex: null,
    hoveredMenuItem: null,
    activeItem: this.props.activePath,
    menuShadow: false,
    ScrollbarTag: PerfectScrollbar,
  };

  mounted = false;

  updateWidth = () => {
    if (this.mounted) {
      this.setState(() => ({
        width: window.innerWidth,
      }));
      this.checkDevice();
    }
  };

  componentDidMount() {
    this.mounted = true;
    if (this.mounted) {
      if (window !== 'undefined') {
        window.addEventListener('resize', this.updateWidth, false);
      }
      this.checkDevice();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  checkDevice = () => {
    var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    var mq = function (query) {
      return window.matchMedia(query).matches;
    };

    if ('ontouchstart' in window || window.DocumentTouch) {
      this.setState({
        ScrollbarTag: 'div',
      });
    } else {
      this.setState({
        ScrollbarTag: PerfectScrollbar,
      });
    }
    var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
  };

  changeActiveIndex = (id) => {
    if (id !== this.state.activeIndex) {
      this.setState({
        activeIndex: id,
      });
    } else {
      this.setState({
        activeIndex: null,
      });
    }
  };

  handleSidebarMouseEnter = (id) => {
    if (id !== this.state.hoveredMenuItem) {
      this.setState({
        hoveredMenuItem: id,
      });
    } else {
      this.setState({
        hoveredMenuItem: null,
      });
    }
  };

  handleActiveItem = (url) => {
    this.setState({
      activeItem: url,
    });
  };

  handleLogOut = async () => {
    await this.props.logoutApp();
    window.location.reload(false);
  };

  render() {
    let {
      visibilityState,
      toggleSidebarMenu,
      sidebarHover,
      toggle,
      color,
      sidebarVisibility,
      activeTheme,
      collapsed,
      activePath,
      sidebarState,
      currentLang,
      permission,
      userRoles,
      collapsedMenuPaths,
    } = this.props;

    let { menuShadow, activeIndex, hoveredMenuItem, activeItem, ScrollbarTag } = this.state;
    let scrollShadow = (container, dir) => {
      if (container && dir === 'up' && container.scrollTop >= 100) {
        this.setState({ menuShadow: true });
      } else if (container && dir === 'down' && container.scrollTop < 100) {
        this.setState({ menuShadow: false });
      } else {
        return;
      }
    };
    return (
      <ContextLayout.Consumer>
        {(context) => {
          let dir = context.state.direction;
          return (
            <React.Fragment>
              <Hammer
                onSwipe={() => {
                  sidebarVisibility();
                }}
                direction={dir === 'rtl' ? 'DIRECTION_LEFT' : 'DIRECTION_RIGHT'}
              >
                <div className="menu-swipe-area d-xl-none d-block vh-100"></div>
              </Hammer>

              <div
                className={classnames(
                  `main-menu menu-fixed menu-light menu-accordion menu-shadow theme-${activeTheme} ${
                    Capacitor.platform === 'ios' ? 'mt-2' : ''
                  }`,
                  {
                    collapsed: sidebarState === true,
                    'hide-sidebar': this.state.width < 1200 && visibilityState === false,
                  },
                )}
                onMouseEnter={() => sidebarHover(false)}
                onMouseLeave={() => sidebarHover(true)}
              >
                <SidebarHeader
                  toggleSidebarMenu={toggleSidebarMenu}
                  toggle={toggle}
                  sidebarBgColor={color}
                  sidebarVisibility={sidebarVisibility}
                  activeTheme={activeTheme}
                  collapsed={collapsed}
                  menuShadow={menuShadow}
                  activePath={activePath}
                  sidebarState={sidebarState}
                />
                <ScrollbarTag
                  className={classnames(`main-menu-content`, {
                    'overflow-hidden': ScrollbarTag !== 'div',
                    'overflow-scroll': ScrollbarTag === 'div',
                  })}
                  {...(ScrollbarTag !== 'div' && {
                    options: { wheelPropagation: false },
                    onScrollDown: (container) => scrollShadow(container, 'down'),
                    onScrollUp: (container) => scrollShadow(container, 'up'),
                    onYReachStart: () =>
                      menuShadow === true &&
                      this.setState({
                        menuShadow: false,
                      }),
                  })}
                >
                  <Hammer
                    onSwipe={() => {
                      sidebarVisibility();
                    }}
                    direction={dir === 'rtl' ? 'DIRECTION_RIGHT' : 'DIRECTION_LEFT'}
                  >
                    <ul className="navigation navigation-main">
                      <SideMenuContent
                        setActiveIndex={this.changeActiveIndex}
                        activeIndex={activeIndex}
                        hoverIndex={hoveredMenuItem}
                        handleSidebarMouseEnter={this.handleSidebarMouseEnter}
                        activeItemState={activeItem}
                        handleActiveItem={this.handleActiveItem}
                        activePath={activePath}
                        lang={currentLang}
                        permission={permission}
                        userRoles={userRoles}
                        collapsedMenuPaths={collapsedMenuPaths}
                        toggleMenu={sidebarVisibility}
                        deviceWidth={this.props.deviceWidth}
                      />
                      <li>
                        <a href="http://iserviceauto.com/technician-training/">
                          <div className="menu-text">
                            <BookOpen size={20} />
                            <span className="ml-1 menu-item menu-title">Training</span>
                          </div>
                        </a>
                      </li>
                      <li>
                        <Link to="/">
                          <div className="menu-text" onClick={this.handleLogOut}>
                            <LogOut size={20} />
                            <span className="ml-1 menu-item menu-title">Logout</span>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </Hammer>
                </ScrollbarTag>
              </div>
            </React.Fragment>
          );
        }}
      </ContextLayout.Consumer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userRoles: state.auth.login.userRoles,
  };
};

export default connect(mapStateToProps, { logoutApp })(Sidebar);
