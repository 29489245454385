/* eslint-disable no-case-declarations */

import { toBool } from 'utility/helpers/format';
import { reducers } from '@jmdigital/iservice3-utils';
import { UserRoles } from '@jmdigital/iservice3-utils/src/constants';

const initialState = {
  currentPage: 1,
  sortField: '',
  sortDirection: '',
  searchTerm: '',
  repairOrderInteractions: null,
  currentMessagePage: 1,
  customers: {},
  error: null, // error object
  filters: [],
  hasNewMessage: false,
  isChatModalOpen: false,
  isCrudModalOpen: false, // so successful CRUD operations can toggle modals
  isFetchingROs: false,
  isLoading: false, // used to show loader and prevent user interaction during requests
  isLockedOut: false,
  isMpiGeneratorOpen: false,
  staleRepairOrderIds: [], // id of stale repair order, triggers dashboard refresh
  laborRates: null,
  latestMessage: null,
  messagetype: '',
  openModal: '',
  priceMatrix: [],
  printableDoc: null,
  paymentFormLoaded: false,
  role: null,
  selectedMpiTemplate: null,
  selectedRecipient: null,
  selectedRow: null,
  unreadMessageCounts: {},
  isPhoneValid: null,
  customerROs: [],

  // Campaigns
  campaign: {},
  campaignTarget: {},
  vehicleMakes: [],
  vehicleModels: [],
  vehicleYears: [],

  // Reports
  advisorReport: {},
  advisorUsageReport: {},
  customerEngagementReport: {},
  financeReporting: {},
  followUpReporting: {},
  iPayReport: {},
  payments: null,
  paymentsNonRO: {},
  mpiReport: {},
  overviewReport: {},
  partsReporting: {},
  roReporting: {},
  technicianReport: {},
  recommendationReport: {},
  advisorRecommendationReport: {},
  technicianRecommendationReport: {},

  // Users
  [UserRoles.SERVICE_ADVISOR]: {},
  [UserRoles.SERVICE_MANAGER]: {},
  [UserRoles.PARTS_ADVISOR]: {},
  [UserRoles.SALES_AGENT]: {},
  [UserRoles.SALES_MANAGER]: {},
  [UserRoles.TECHNICIAN]: {},

  // Settings
  settings: null,
  environmentSettings: null,
  opCodes: {},
  mpiTemplates: [],
  coupons: {},
  parts: {},
  rewardsTier: {},
  transportationType: {},
  serviceLaneOffHour: {},

  // Follow Up
  followUpTargets: [],

  // myReview
  myReviewAdvisorReport: [],
  roReport: [],

  // Dashboards
  financeDashboard: {},
  images: [],
  mpiTemplate: null,
  ros: {},
  upgrade: {},
  videos: [],

  // Messaging
  threads: {},
  messages: {},

  //CheckIn
  checkIn: {},
};

const ACTIVE_MODULES = [
  'iServiceActivated',
  'mpi',
  'video',
  'quote',
  'checkin',
  'myReviewActivated',
  'myLoan',
  'rewards',
  'parts',
  'nmi',
  'heartland',
  'scheduler',
];

const memberReducer = (state = initialState, action) => {
  const { data, stateProp } = action;
  switch (action.type) {
    case 'SET_STATE':
      return { ...state, [action.key]: action.value };
    case 'SET_FILTERS':
      return { ...state, filters: data };
    case 'ACCOUNT_INFORMATION_UPDATED':
      return { ...state, isLoading: false };
    case 'CLOSE_REPAIR_ORDER':
      return { ...state, isLoading: true };
    case 'RO_OUTDATED':
      const staleRepairOrderIds = [...state.staleRepairOrderIds];
      if (state.selectedRow?.id) {
        staleRepairOrderIds.push(state.selectedRow.id);
      }
      if (action.forceUpdate) {
        // Hack to force GET ROs on creating new record (ID not available yet)
        staleRepairOrderIds.push(-1);
      }

      return {
        ...state,
        staleRepairOrderIds,
      };
    case 'CREATE_RECORD':
      return { ...state, isLoading: true };
    case 'CUSTOMER_CREATED':
      return { ...state, selectedRecipient: data, isPhoneValid: null };
    case 'RECEIVE_REPAIR_ORDER_INTERACTIONS':
      return { ...state, repairOrderInteractions: data };
    case 'DELETE_RECORD':
      return { ...state, isLoading: true };
    case 'DISMISS_ERROR':
      return { ...state, error: null };
    case 'GET_MPI_TEMPLATES':
      return {
        ...state,
        isLoading: true,
        mpiTemplate: null,
        mpiTemplates: [],
      };
    case 'GET_MPI_TEMPLATE_BY_ID':
      return { ...state, mpiTemplate: null, isLoading: true };
    case 'GET_RECORDS':
      return { ...state, isLoading: true, [stateProp]: {} };
    case 'GET_REPAIR_ORDER_IMAGES':
      return { ...state, images: [], isLoading: true };
    case 'REPAIR_ORDER_IMAGE_UPDATED':
      return { ...state, isLoading: false };
    case 'GET_ROS':
      return { ...state, isFetchingROs: true };
    case 'RECEIVE_ROS':
      return {
        ...state,
        ros: data,
        isFetchingROs: false,
        staleRepairOrderIds: [],
      };
    case 'POLL_ROS_GET':
      return { ...state, isFetchingROs: true };
    case 'POLL_ROS_CANCEL':
      return { ...state, isFetchingROs: false };
    case 'POLL_ROS_RECEIVE':
      return {
        ...state,
        ros: data,
        isFetchingROs: false,
        staleRepairOrderIds: [],
      };
    case 'GET_SETTINGS':
      return { ...state, isLoading: true };
    case 'GET_VIDEOS_BY_RO_ID':
      return { ...state, videos: [], isLoading: true };
    case 'HANDLE_ERROR':
      return { ...state, isLoading: false, error: action.error };
    case 'MPI_RESULTS_SUBMITTED':
      return {
        ...state,
        isLoading: false,
        mpiTemplate: null,
        mpiTemplates: [],
      };
    case 'MPI_TEMPLATE_SUBMITTED':
      return { ...state, isLoading: false, isMpiGeneratorOpen: false };
    case 'RECEIVE_MPI_TEMPLATES':
      return { ...state, mpiTemplates: data, isLoading: false };
    case 'RECEIVE_MPI_TEMPLATE_BY_ID':
      return { ...state, mpiTemplate: data, isLoading: false };
    case 'RECEIVE_OP_CODES':
      return { ...state, opCodes: data, isLoading: false };
    case 'RECEIVE_VIDEOS_BY_RO_ID':
      return { ...state, videos: data, isLoading: false };
    case 'RECEIVE_RECORD':
      return {
        ...state,
        isLoading: false,
      };
    case 'RECEIVE_RECORDS':
      if (stateProp === 'paymentsNonRO' && data?.results) {
        const mutated = data.results?.map((result) => {
          result.customerId = result.customer?.id;
          return result;
        });
        data.results = mutated;
      }

      return {
        ...state,
        isLoading: false,
        [stateProp]: data,
        staleRepairOrderIds: stateProp === 'ros' ? [] : state.staleRepairOrderIds,
      };
    case 'RECEIVE_REPAIR_ORDER_IMAGES':
      return { ...state, images: data, isLoading: false };
    case 'RECEIVE_ENVIRONMENT_SETTINGS':
      return { ...state, environmentSettings: data };
    case 'RECEIVE_SETTINGS':
      return reducers.receiveSettings(
        state,
        Array.isArray(action.data) ? action : { ...action, data: Object.values(action.data) },
        (settings) => {
          const converted = {};
          ACTIVE_MODULES.forEach((key) => {
            converted[key] = toBool(settings[key]);
          });

          converted.hasPayments = converted.nmi || converted.heartland;

          return {
            isLoading: false,
            settings: {
              ...settings,
              ...converted,
            },
          };
        },
      );
    case 'RECEIVE_PARTS':
      return { ...state, parts: data, isLoading: false };
    case 'RECEIVE_VEHICLE_LISTS':
      const vehicleMakes = data[0].data;
      const vehicleModels = data[1].data;
      const vehicleYears = data[2].data;

      return { ...state, vehicleMakes, vehicleModels, vehicleYears };
    case 'GET_PRICE_MATRIX':
      return { ...state, priceMatrix: [] };
    case 'RECEIVE_PRICE_MATRIX':
      return { ...state, priceMatrix: data };
    case 'RECEIVE_THREADS':
      return { ...state, threads: data };
    case 'RECEIVE_MESSAGES':
      return { ...state, messages: data };
    case 'RECORD_CREATED':
      return { ...state, isCrudModalOpen: false, isLoading: false };
    case 'RECORD_DELETED':
      return { ...state, isCrudModalOpen: false, isLoading: false };
    case 'RECORD_UPDATED':
      return { ...state, isCrudModalOpen: false, isLoading: false };
    case 'RESEND_WAIVER':
      return { ...state, isLoading: true };
    case 'REPAIR_ORDER_CLOSED':
      return { ...state, isLoading: false };
    case 'RESET_MEMBER_STATE':
      return initialState;
    case 'SET_CURRENT_PAGE':
      return { ...state, currentPage: action.page };
    case 'SET_IS_LOCKED_OUT':
      return { ...state, isLockedOut: data };
    case 'RESET_IS_LOCKED_OUT':
      return { ...state, isLockedOut: false };
    case 'SET_MESSAGE_TYPE':
      return { ...state, messageType: data };
    case 'SET_SELECTED_ROW':
      return {
        ...state,
        selectedRecipient: data?.primaryCustomer,
        selectedRow: data,
        repairOrderInteractions: null,
        images: [],
      };
    case 'SET_MODULE_VISIBILITY':
      return {
        ...state,
        appModules: {
          ...state.appModules,
          [data.module]: data.value,
        },
      };
    case 'CLEAR_SELECTED_REPAIR_ORDER':
      return { ...state, selectedRow: null };
    case 'SUBMIT_MPI_RESULTS':
      return { ...state, isLoading: true };
    case 'TOGGLE_CRUD_MODAL':
      return { ...state, isCrudModalOpen: !state.isCrudModalOpen };
    case 'TOGGLE_MPI_GENERATOR':
      return { ...state, isMpiGeneratorOpen: !state.isMpiGeneratorOpen };
    case 'UPDATE_RECORD':
      return { ...state, isLoading: true };
    case 'UPDATE_SELECTED_MPI_TEMPLATE':
      return { ...state, selectedMpiTemplate: data };
    case 'UPDATE_SETTINGS':
      return { ...state, isLoading: true };
    case 'UPDATE_TABLE_PARAMETER':
      return { ...state, ...action.params };
    case 'UPDATE_UNREAD_MESSAGE_COUNTS':
      return { ...state, unreadMessageCounts: data };
    case 'WAIVER_SENT':
      return { ...state, isLoading: false };
    case 'PAYMENT_FORM_ERRORED':
      return { ...state, paymentFormLoaded: false };
    case 'PAYMENT_FORM_LOADED':
      return { ...state, paymentFormLoaded: true };
    case 'CLEAR_PAYMENTS':
      return { ...state, payments: null };
    case 'RECEIVE_CAMPAIGN_TARGET':
      return { ...state, campaignTarget: data };
    case 'RECEIVE_PAYMENTS':
      return { ...state, payments: data };
    case 'RECEIVE_PAYMENT':
      return { ...state, payment: data };
    case 'LOADING_START':
      return { ...state, isLoading: true };
    case 'LOADING_END':
      return { ...state, isLoading: false };
    case 'RECEIVE_LABOR_RATES':
      return { ...state, laborRates: data };
    case 'GET_ROS_BY_CUSTOMER_PHONE':
      return { ...state, customerROs: [] };
    case 'RECEIVE_ROS_BY_CUSTOMER_PHONE':
      return { ...state, customerROs: data, isLoading: false };
    case 'SET_OPEN_DASHBOARD_MODAL':
      return { ...state, openModal: action.name };
    case 'UPDATE_LATEST_MESSAGE':
      return {
        ...state,
        hasNewMessage: data.hasNewMessage,
        latestMessage: data.message,
      };
    case 'RESET_MESSAGES':
      return { ...state, latestMessage: null, messages: null };
    case 'SET_CURRENT_MESSAGE_PAGE':
      return { ...state, currentMessagePage: data };
    case 'SET_SELECTED_RECIPIENT':
      return { ...state, selectedRecipient: data };
    case 'SET_PRINTABLE_DOC_DATA':
      return { ...state, printableDoc: data };
    case 'UPDATE_MESSAGES':
      return { ...state, hasNewMessage: false, messages: data };
    case 'VALIDATING_MOBILE_PHONE':
      return { ...state, isPhoneValid: null };
    case 'MOBILE_PHONE_VALIDATED':
      return { ...state, isPhoneValid: data };
    case 'TOGGLE_MOBILE_VALIDATION_CONFIRM_MODAL':
      return { ...state, isPhoneValid: null };
    case 'TOGGLE_CHAT_MODAL':
      return {
        ...state,
        isChatModalOpen: !state.isChatModalOpen,
        selectedRecipient: null,
      };
    default:
      return state;
  }
};

export default memberReducer;
