import { Toast } from '@capacitor/toast';
import { Dialog } from '@capacitor/dialog';

export const showToast = async (message = '') => {
  await Toast.show({
    text: message,
    position: 'bottom',
    duration: 'long',
  });
};

export const showSuccess = async (msg) => {
  await Dialog.alert({
    title: 'Success',
    message: msg,
    buttonTitle: 'Done',
  });
};

export const showError = async (err, message) => {
  const msg =
    message ||
    err.message ||
    err.response?.data?.message ||
    err.response?.status ||
    `Something went wrong`;

  await Dialog.alert({
    title: ``,
    message: msg,
    buttonTitle: 'Done',
  });
};
