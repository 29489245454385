import { showError } from 'toast';
import clientApi from 'api/clientApi';
import { getRoList } from 'redux/actions/app';
import { logoutApp } from 'redux/actions/auth';
import { Endpoints } from 'utility/constants';
import { getStorageValue } from 'utility/helpers';

export const refresh = () => async (dispatch) => {
  dispatch({ type: 'REFRESH' });

  const appToken = await getStorageValue('iservice_technician_app_token');
  const techToken = await getStorageValue('iservice_technician_tech_token');

  if (appToken && !techToken) return dispatch(getTechList());
  if (appToken && techToken) return dispatch(getRoList());

  dispatch(logoutApp());
};

export const getStorage = () => async (dispatch) => {
  const appToken = await getStorageValue('iservice_technician_app_token');
  const techToken = await getStorageValue('iservice_technician_tech_token');
  const subdomain = await getStorageValue('iservice_technician_subdomain');

  dispatch({ type: 'APP_LOGGED_IN' });
  return {
    appToken,
    techToken,
    subdomain,
  };
};

export const setStore = (data) => {
  return (dispatch) => {
    dispatch({ type: 'SET_STORE', data });
  };
};

export const handleError = (error, message) => {
  return async (dispatch) => {
    dispatch({ type: 'HANDLE_ERROR', error });

    if (error?.response?.status !== 401) {
      return showError(error, message);
    }

    const appToken = await getStorageValue('iservice_technician_app_token');
    const techToken = await getStorageValue('iservice_technician_tech_token');

    if (!techToken || !appToken) {
      showError(error, 'Please log in');
      return window.location.assign('/');
    }

    return dispatch({ type: 'TECH_LOGIN_EXPIRED' });
  };
};

export const getTechList = () => async (dispatch) => {
  dispatch({ type: 'LOADING' });
  const config = {
    params: {
      role: 'ROLE_TECHNICIAN',
    },
  };

  try {
    const response = await clientApi.get(Endpoints.users, config);
    dispatch(receiveTechList((response.data && response.data.results) || []));
    dispatch({ type: 'CLEAR_CURRENT_RO' });
  } catch (error) {
    dispatch(logoutApp());
    dispatch(handleError(error, 'Login Expired!'));
  }
};

export const receiveTechList = (data) => {
  return (dispatch) => {
    dispatch({ type: 'RECEIVE_TECH_LIST', data });
  };
};

export const setCheckInList = (list) => async (dispatch) => {
  dispatch({ type: 'SET_CHECK_IN_LIST', data: list });
};
