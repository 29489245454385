export const tokens = (state = { appToken: null, techToken: null, isLoading: true }, action) => {
  switch (action.type) {
    case 'LOAD_TOKENS': {
      return { ...state, ...action.payload, isLoading: false };
    }
    default: {
      return state;
    }
  }
};
