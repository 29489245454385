import React from 'react';
import * as Icon from 'react-feather';
import { UserRoles } from '@jmdigital/iservice3-utils/src/constants';

const navigationConfig = [
  {
    id: 'iService',
    title: 'Technicians',
    type: 'item',
    icon: <Icon.User size={20} />,
    permissions: [
      UserRoles.ADMIN,
      UserRoles.PARTS_ADVISOR,
      UserRoles.SERVICE_ADVISOR,
      UserRoles.TECHNICIAN,
    ],
    navLink: '/',
  },
  {
    id: 'checkIn',
    title: 'CHECKin',
    type: 'item',
    icon: <Icon.MapPin size={20} />,
    permissions: [UserRoles.ADMIN, UserRoles.PARTS_ADVISOR, UserRoles.SERVICE_ADVISOR],
    navLink: '/checkin',
  },
  // { // Is on hold right now
  //   id: "spotLight",
  //   title: "spotLIGHT",
  //   type: "item",
  //   icon: <Icon.Sun size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/spotlight"
  // },
  // {
];

export default navigationConfig;
