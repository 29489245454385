export const login = (state = { userRoles: [] }, action) => {
  switch (action.type) {
    case 'CHANGE_ROLE': {
      return { ...state, userRoles: action.userRoles };
    }
    case 'LOGOUT_APP': {
      return { ...state, userRoles: [] };
    }
    default: {
      return state;
    }
  }
};
