import axios from 'axios';
import qs from 'qs';
import { store } from 'redux/storeConfig/store';
import { Endpoints } from 'utility/constants';
import { getStorageValue } from 'utility/helpers';
import { Network } from '@capacitor/network';

const instance = axios.create();
instance.interceptors.request.use(
  async (config) => {
    const networkStatus = await Network.getStatus();
    if (!networkStatus.connected) {
      throw new Error('Unable to connect. Please check your internet connection.');
    }

    const subdomain = await getStorageValue('iservice_technician_subdomain');
    const techToken = await getStorageValue('iservice_technician_tech_token');
    const appToken = await getStorageValue('iservice_technician_app_token');

    const { isTechLoginExpired } = store.getState().app;
    const token = (() => {
      // Prioritize tech token for certain endpoints
      if ([Endpoints.ros].includes(config.url)) {
        // For creating new ROs
        if (['post', 'put'].includes(config.method)) {
          return appToken ?? techToken;
        }

        // For getting a list of ROs
        return techToken ?? appToken;
      }

      // Prioritize service advisor token for certain endpoints
      if ([Endpoints.users].includes(config.url)) {
        return appToken ?? techToken;
      }

      // Otherwise, techToken if not expired, appToken as fallback
      return !isTechLoginExpired ? techToken ?? appToken : appToken;
    })();

    config.baseURL = `https://${subdomain}.iserviceauto.com/api`;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (config.method === 'post') {
      if (!config.data) throw new Error('Please provide a request body');
      config.transformRequest = (data) => {
        const formData = new FormData();

        for (const key in data) {
          formData.append(key, data[key]);
        }

        return formData;
      };
    }

    if (config.method === 'patch') {
      if (!config.data) throw new Error('Please provide a request body');

      config.headers['Content-Type'] = 'application/x-www-form-urlencoded';

      config.transformRequest = (data) => {
        const params = new URLSearchParams();

        for (const key in data) {
          params.append(key, data[key]);
        }

        return params.toString();
      };
    }

    if (config.method === 'put') {
      config.transformRequest = (data) => qs.stringify(data);
    }

    if (config.method === 'get') {
      config.paramsSerializer = (params) => qs.stringify(params);
    }

    if (config.method === 'delete') {
      const endsWithNumber = /.*[0-9]$/;
      if (!endsWithNumber.test(config.url))
        throw new Error('Please provide id for the entity you wish to delete');
    }

    return config;
  },
  (response) => response,
);

export default instance;
