import { showToast } from 'toast';
import clientApi from 'api/clientApi';
import { getRoById, handleError } from 'redux/actions/app';
import { store } from 'redux/storeConfig/store';
import { Endpoints } from 'utility/constants';

const CONTACT_MESSAGE = ' Please try again or contact support.';

export const updateQuote = (recommendations) => async (dispatch) => {
  dispatch({ type: 'LOADING' });

  const repairOrder = store.getState().app.currentRepairOrder;
  const quoteId = repairOrder?.repairOrderQuote?.id;

  try {
    if (!recommendations.length) {
      throw new Error('Please provide at least one recommendation');
    }

    const json = JSON.stringify(recommendations);
    const response = quoteId
      ? await clientApi.put(`${Endpoints.quote}/${quoteId}`, {
          recommendations: json,
        })
      : await clientApi.post(Endpoints.quote, {
          recommendations: json,
          repairOrderID: repairOrder.id,
        });
    if (response.status === 200) {
      showToast('Quote updated');
      dispatch(getRoById(repairOrder.id));
    } else {
      throw new Error('Failed to update quote. Please check your wifi connection and try again.');
    }
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const setQuoteInProgress = (repairOrder, status) => async (dispatch) => {
  dispatch({ type: 'LOADING' });

  const data = {
    repairOrderQuoteID: repairOrder.repairOrderQuote.id,
    status,
  };

  try {
    const response = await clientApi.post(`${Endpoints.quote}/in-progress`, data);

    if (response.status !== 200) {
      throw new Error('Failed to send quote. Please try again or contact support.');
    }

    showToast('Quote sent');
    dispatch({ type: 'QUOTE_SENT' });
    dispatch(getRoById(repairOrder.id));

    return true;
  } catch (error) {
    dispatch(handleError(error));
    return false;
  }
};

export const syncQuote = (repairOrderId) => async (dispatch) => {
  dispatch({ type: 'LOADING_START' });
  try {
    const syncResponse = await clientApi.post(`${Endpoints.syncQuote}`, {
      id: repairOrderId,
    });
    if (syncResponse.status !== 200)
      throw new Error('Quote sync may have failed; please try again.');

    const rosResponse = await clientApi.get(`${Endpoints.ros}/${repairOrderId}`);
    if (rosResponse.status !== 200)
      throw new Error(
        `Quote sync succeeded, but failed to get the updated Repair Order. Please refresh to see any sync'd changes.`,
      );

    dispatch({ type: 'SET_CURRENT_RO', data: rosResponse.data });
    dispatch({ type: 'LOADING_END' });
    showToast({
      message: 'Quote Refreshed',
    });

    return true;
  } catch (error) {
    return dispatch(handleError(error));
  }
};

export const unlockQuoteLocally = () => (dispatch) => {
  dispatch({ type: 'UNLOCK_QUOTE_LOCALLY' });
};

export const lockQuoteLocally = () => (dispatch) => {
  dispatch({ type: 'LOCK_QUOTE_LOCALLY' });
};

export const unlockQuote = (repairOrderId) => (dispatch) => {
  dispatch({ type: 'UNLOCK_QUOTE' });
  dispatch(lockQuote(repairOrderId, false));
};

export const lockQuote =
  (repairOrderId, lock = true) =>
  async (dispatch) => {
    if (lock) dispatch({ type: 'LOCK_QUOTE' });

    try {
      await clientApi.post(Endpoints.quoteInProgress, {
        repairOrderId,
        lock,
      });

      // Regardless of whether we lock it or unlock it at the db, it should be unlocked in the app
      dispatch({ type: 'UNLOCK_QUOTE_LOCALLY' });
      return true;
    } catch (err) {
      if (err.response?.status === 400) {
        dispatch({ type: 'LOCK_QUOTE_LOCALLY' });
      }
    }
  };

export const submitRecommendations = (recommendations, repairOrder) => async (dispatch) => {
  dispatch({ type: 'LOADING' });

  const data = {
    recommendations: JSON.stringify(recommendations),
    repairOrderID: repairOrder.id,
  };
  try {
    let response;
    if (repairOrder?.repairOrderQuote || repairOrder?.repairOrderQuote?.length) {
      response = await clientApi.put(`${Endpoints.quote}/${repairOrder.repairOrderQuote.id}`, data);
    } else {
      response = await clientApi.post(Endpoints.quote, data);
    }
    if (response.status !== 200) {
      throw new Error('Failed to submit recommendations. Please try again or contact support.');
    }
    dispatch({ type: 'RECOMMENDATIONS_SUBMITTED' });
    return true;
  } catch (error) {
    dispatch(handleError(error));
    return false;
  }
};

export const getParts = (params) => async (dispatch) => {
  dispatch({ type: 'GET_PARTS' });
  // Next line is temporary until backend fixes issue when results > pageLimit
  params.pageLimit = 10;
  try {
    const response = await clientApi.get(Endpoints.parts, {
      params,
    });

    if (response.status === 200) {
      dispatch({ type: 'RECEIVE_PARTS_NO_STATE_UPDATE' });

      return response;
    } else throw new Error(`Failed to get users.${CONTACT_MESSAGE}`);
  } catch (error) {
    dispatch(handleError(error));
    return [];
  }
};
