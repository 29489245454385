import { showToast } from 'toast';
import { history } from 'history.js';
import clientApi from 'api/clientApi';
import { getRoList, handleError } from 'redux/actions/app';
import { Endpoints } from 'utility/constants';
import { getStorageValue, removeStorage, setStorageObj } from 'utility/helpers';
import { UserRoles } from '@jmdigital/iservice3-utils/src/constants';

const subdomainWhitelist = ['docker'];

const iservice_technician_advisor_id = 'iservice_technician_advisor_id';
const iservice_technician_tech_id = 'iservice_technician_tech_id';

export const changeRole = (roles) => {
  return (dispatch) => dispatch({ type: 'CHANGE_ROLE', userRoles: roles });
};

export const logoutApp = () => async (dispatch) => {
  removeStorage('iservice_technician_app_token');
  removeStorage('iservice_technician_tech_token');
  removeStorage('iservice_technician_tech_username');
  removeStorage(iservice_technician_advisor_id);
  removeStorage(iservice_technician_tech_id);
  removeStorage('userRoles');

  dispatch({ type: 'LOGOUT_APP' });
};

export const logoutTech = () => async (dispatch) => {
  removeStorage('iservice_technician_tech_token');
  removeStorage('iservice_technician_tech_username');
  removeStorage(iservice_technician_tech_id);
  dispatch({ type: 'TECH_LOGGED_OUT' });
  history.push('/');
  showToast('Technician logged out');
};

export const loginApp = (username, password, subdomain) => async (dispatch) => {
  dispatch({ type: 'LOADING' });
  const credentials = { username, password };

  const subdomainWithPrefix = addSubdomainPrefix(subdomain);

  setStorageObj('iservice_technician_subdomain', subdomainWithPrefix);

  try {
    const response = await clientApi.post(Endpoints.authentication, credentials);
    console.log('APP USER', response.data.user);
    removeStorage('iservice_technician_tech_token');
    removeStorage('iservice_technician_tech_username');

    removeStorage(iservice_technician_advisor_id);
    removeStorage(iservice_technician_tech_id);

    const appToken = response.data.token;

    const payload = {
      appToken: appToken,
      subdomain: subdomainWithPrefix,
    };

    // `response.data.user` is not always guaranteed to be defined
    const userId = response.data.user?.id;

    if (response.data.roles.includes(UserRoles.SERVICE_ADVISOR) && userId) {
      await setStorageObj(iservice_technician_advisor_id, userId);
      payload.advisorId = userId;
    }

    setStorageObj('iservice_technician_app_token', appToken);
    setStorageObj('userRoles', response.data.roles);

    dispatch({ type: 'APP_LOGGED_IN', data: response.data.user });
    dispatch({ type: 'CHANGE_ROLE', userRoles: response.data.roles });

    dispatch({
      type: 'LOAD_TOKENS',
      payload,
    });
  } catch (error) {
    let message = null;

    if (error?.message === 'Network Error') {
      message = 'Something went wrong! Please try again.';
    } else if (error?.response?.status === 403) {
      message = 'Incorrect username or password.';
    }

    return dispatch(handleError(error, message));
  }
};

export const loginTech = (tech, pin) => async (dispatch) => {
  dispatch({ type: 'LOADING' });
  const credentials = { username: tech.email, pin };
  try {
    const response = await clientApi.post(Endpoints.authentication, credentials);
    console.log('TECH USER', response.data.user);

    tech.techToken = response.data.token;
    setStorageObj('iservice_technician_tech_token', tech.techToken);
    setStorageObj('iservice_technician_tech_username', tech.email);
    setStorageObj(iservice_technician_tech_id, response.data.user.id);

    dispatch({ type: 'TECH_LOGGED_IN', data: response.data.user });

    dispatch({
      type: 'LOAD_TOKENS',
      payload: { techToken: tech.techToken, techId: response.data.user.id },
    });

    dispatch(getRoList());
    return true;
  } catch (error) {
    if (error?.response?.status === 403) {
      return dispatch(handleError(error, 'Incorrect PIN.'));
    }

    return dispatch(handleError(error));
  }
};

const addSubdomainPrefix = (subdomain) => {
  const prefix = 'api.';
  return subdomain.includes(prefix) || subdomainWhitelist.includes(subdomain)
    ? subdomain
    : `${prefix}${subdomain}`;
};

export const loadTokens = () => async (dispatch) => {
  const [appToken, techToken, subdomain, advisorId, techId] = await Promise.all([
    getStorageValue('iservice_technician_app_token'),
    getStorageValue('iservice_technician_tech_token'),
    getStorageValue('iservice_technician_subdomain'),
    getStorageValue(iservice_technician_advisor_id),
    getStorageValue(iservice_technician_tech_id),
  ]);

  const payload = {};

  if (appToken) payload.appToken = appToken;

  if (techToken) payload.techToken = techToken;

  if (subdomain) payload.subdomain = subdomain;

  if (advisorId) payload.advisorId = Number(advisorId);

  if (techId) payload.techId = Number(techId);

  dispatch({ type: 'LOAD_TOKENS', payload });
};

export const loadUserRoles = () => async (dispatch) => {
  const userRoles = await getStorageValue('userRoles');

  if (userRoles) {
    dispatch({ type: 'CHANGE_ROLE', userRoles });
  }
};
