import { showSuccess } from 'toast';
import { getRoById } from 'redux/actions/app';
import { store } from 'redux/storeConfig/store';
import { getStorageValue } from 'utility/helpers';

export const uploadVideo = (params, videoObj, apiEndpoint) => async (dispatch) => {
  dispatch({ type: 'LOADING' });

  const appToken = await getStorageValue('iservice_technician_app_token');
  const techToken = await getStorageValue('iservice_technician_tech_token');
  const token = techToken || appToken;
  const subdomain = await getStorageValue('iservice_technician_subdomain');
  const url = `https://${subdomain}.iserviceauto.com/api/${apiEndpoint}`;

  const formData = new FormData();
  formData.append('video', videoObj.data, videoObj.fileName);
  formData.append('repairOrderID', params.repairOrderID || ''); // Adjust this as needed

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  };

  dispatch({
    type: 'UPDATE_PROGRESS',
    data: 0,
  });

  return fetch(url, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      dispatch({ type: 'UPDATE_PROGRESS', data: 100 });
      await dispatch({ type: 'SEND_VIDEO' });

      if (params.repairOrderID) {
        await dispatch(getRoById(params.repairOrderID));
      }

      dispatch({ type: 'LOADING_END' });
      showSuccess('Video uploaded');
    })
    .catch((error) => {
      dispatch({ type: 'UPDATE_PROGRESS', data: 0 });

      if (params.repairOrderID) {
        dispatch(getRoById(params.repairOrderID));
      }

      dispatch({ type: 'LOADING_END' });
      throw new Error(error);
    });
};
