import { Preferences } from '@capacitor/preferences';

export const getStorageValue = async (storageKey) => {
  const { value } = await Preferences.get({ key: storageKey });

  return value;
};

export const removeStorage = async (storageKey) => {
  return await Preferences.remove({ key: storageKey });
};

export const setStorageObj = async (key, value) => {
  return await Preferences.set({
    key,
    value,
  });
};
