const format = {};
export default format;

/*
    Converts truthy/falsy strings from the API to booleans.
    The API returns a string.
*/
export const toBool = (val) => {
  return val === '1' || val === 'true' || val === true;
};
