import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from 'serviceWorker';
import Spinner from 'components/@vuexy/spinner/Fallback-spinner';
import { store } from 'redux/storeConfig/store';
import { Layout } from 'utility/context/Layout';
import 'index.scss';
import '@fake-db';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
const LazyApp = lazy(() => import('./App'));
defineCustomElements(window);
const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <Layout>
          <LazyApp />
        </Layout>
      </Suspense>
    </Provider>,
    document.getElementById('root'),
  );
};

if (!window.cordova) {
  render();
} else {
  document.addEventListener('deviceready', render, false);
}

// configureDatabase()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
