import { combineReducers } from 'redux';
import { login } from 'redux/reducers/auth/loginReducer';
import { tokens } from 'redux/reducers/auth/tokensReducer';

const authReducers = combineReducers({
  login,
  tokens,
});

export default authReducers;
