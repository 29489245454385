import { combineReducers } from 'redux';
import app from 'redux/reducers/app';
import auth from 'redux/reducers/auth';
import customizer from 'redux/reducers/customizer';
import member from 'redux/reducers/member';
import navbar from 'redux/reducers/navbar/Index';

const rootReducer = combineReducers({
  customizer,
  auth,
  member,
  navbar,
  app,
});

export default rootReducer;
