import clientApi from 'api/clientApi';
import { handleError } from 'redux/actions/app';
import { Endpoints } from 'utility/constants';

const CONTACT_MESSAGE = ' Please try again or contact support.';

export const getRoById = (repairOrderId) => async (dispatch) => {
  dispatch({ type: 'LOADING' });
  try {
    const laborRatesResponse = await clientApi.get(`${Endpoints.laborRates}`);
    const roResponse = await clientApi.get(`${Endpoints.ros}/${repairOrderId}`);
    const settingsResponse = await clientApi.get(`${Endpoints.settings}`);
    const settings = settingsResponse.data.reduce((acc, setting) => {
      acc[setting.key] = setting.value;
      return acc;
    }, {});

    if (
      settings.pricingUseMatrix === 'true' ||
      settings.pricingUseMatrix === '1' ||
      settings.pricingUseMatrix === true
    ) {
      const matrixResponse = await clientApi.get(Endpoints.priceMatrix);

      dispatch({
        type: 'RECEIVE_PRICE_MATRIX',
        data: matrixResponse.data,
      });
    }

    dispatch({
      type: 'RECEIVE_LABOR_RATES',
      data: laborRatesResponse.data.results,
    });
    dispatch({
      type: 'RECEIVE_SETTINGS',
      data: settings,
    });
    dispatch({
      type: 'SET_CURRENT_RO',
      data: roResponse.data,
    });
    dispatch({ type: 'LOADING_END' });
  } catch (error) {
    dispatch(handleError(error));
  }
};
export const getRoByIdOnly = (id) => async (dispatch) => {
  dispatch({ type: 'LOADING_START' });
  dispatch({ type: 'GET_RO_BY_ID_ONLY' });

  try {
    const response = await clientApi.get(`${Endpoints.ros}/${id}`);

    dispatch({
      type: 'SET_CURRENT_RO',
      data: response.data,
    });
    dispatch({ type: 'LOADING_END' });
  } catch (error) {
    dispatch(handleError(error));
  }
};
export const getRoList =
  ({ open = true, needsVideo = undefined } = {}) =>
  async (dispatch) => {
    dispatch({ type: 'LOADING' });
    const config = {
      params: {
        open,
        needsVideo,
        sortField: 'dateCreated',
        sortDirection: 'DESC',
        page: 1,
        pageLimit: 999,
      },
    };
    try {
      const response = await clientApi.get(Endpoints.ros, config);
      dispatch(receiveRoList(response.data.results));
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const pollForRepairOrders = () => async (dispatch) => {
  const params = {
    open: true,
    sortField: 'dateCreated',
    sortDirection: 'DESC',
  };
  try {
    const response = await clientApi.get(Endpoints.ros, params);
    dispatch(receiveRoList(response.data.results));
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const receiveRoList = (roList) => {
  return (dispatch) => {
    dispatch({ type: 'RECEIVE_RO_LIST', data: roList });
  };
};

export const getOpCodes = (params) => async (dispatch) => {
  dispatch({ type: 'GET_OP_CODES' });
  try {
    const response = await clientApi.get(Endpoints.opCodes, {
      params,
    });
    if (response.status === 200) {
      return response;
    } else throw new Error(`Failed to get Op. Codes.${CONTACT_MESSAGE}`);
  } catch (error) {
    dispatch(handleError(error));
    return [];
  }
};

export const getMpiItemOpCode = (mpiItemId) => async () => {
  try {
    const response = await clientApi.get(`${Endpoints.mpiItemOpCode}/${mpiItemId}`);
    if (response.status === 200) {
      return response;
    } else throw new Error(`Failed to get MpiItemOpCode.${CONTACT_MESSAGE}`);
  } catch (error) {
    // dispatch(handleError(error));
    return [];
  }
};
